var enableUpcomingContent = process.env.NEXT_PUBLIC_ENABLE_UPCOMING_CONTENT;
var noteworthyContentApiUrl = process.env.NEXT_PUBLIC_NOTEWORTHY_CONTENT_API_URL;
var pod = process.env.NEXT_PUBLIC_POD;
var assetPath = process.env.NEXT_PUBLIC_ASSET_PATH;
var assetRoutePrefix = process.env.NEXT_PUBLIC_ASSET_ROUTE_PREFIX;

if (!assetRoutePrefix || assetRoutePrefix.substring(1) === '{ASSET_ROUTE_PREFIX}') {
  assetRoutePrefix = process.env.ASSET_ROUTE_PREFIX || '/noteworthy'; // this is a required fallback (specific to this app), because of how var substitution is set up here
}

var serviceName = process.env.NEXT_PUBLIC_DD_SERVICE;
var debugVersion = process.env.NEXT_PUBLIC_DEBUG_VERSION;
var currentEnvironment = process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT;
module.exports = {
  app: 'noteworthy',
  enableUpcomingContent: enableUpcomingContent,
  noteworthyContentApiUrl: noteworthyContentApiUrl,
  pod: pod,
  assetRoutePrefix: assetRoutePrefix,
  assetPath: assetPath,
  serviceName: serviceName,
  debugVersion: debugVersion,
  currentEnvironment: currentEnvironment
};