export const COLOR = {
  WHITE: '#ffffff', // backgrounds/text
  BLACK: '#38393c', // text
  RED: '#e91019', // text/borders/backgrounds
  BLUE: '#1387ba', // links
  BLUE_HOVER: '#1f5a8b',
  GREY: '#747474',
  LIGHTGREY: '#ebebeb', // typically used for borders
  LIGHTGREY2: '#f1f1f1',
  LIGHTGREY3: '#f7f7f7',
  DARKGREY: '#333',
  SEPARATOR: '#d8d8d8',
  SEPARATOR2: '#c9c9c9',

  ARTICLE: '#f36b33',
  INTERVIEW: '#ffa21a',
  REPORT: '#e91019',
  AUDIO: '#fa5f93',
  VIDEO: '#944bbb',
  CHART: '#7a69e6',
  FORECASTS: '#27ceb6',
  PERFORMANCE: '#77ce44',
  WEBINAR: '#6236FF',

  TRENDING: '#f7575',
  BACKGROUND: '#ffffff',
  GREY_BLACK: '#333333',
  BLUE_BACKGROUND: '#1387ba',
  SOFTBLACK: '#555',
  BLUE_PRIMARY: '#2b7cac',
  GRAY_BACKGROUND: '#f2f2f2',

  DOWNLOADS: {
    DOCX: '#295596',
    EPUB: '#747474',
    FILE: '#fa5f93',
    GIF: '#B93D81',
    JPG: '#409FF1',
    PDF: '#e91019',
    PNG: '#FA9F1B',
    PPT: '#f36b33',
    XLS: '#207146'
  }
};

export const FONT = {
  COLOR: {
    BLACK: '#38393c',
    WHITE: '#FFF'
  },
  FAMILY: "'Linotype Univers W01', 'Arial Narrow', Arial, Helvetica, sans-serif",
  FAMILYROBO: 'Roboto, sans-serif',
  FAMILYLABGREGULAR: 'LabGrotesque-Regular-subset, sans-serif',
  FAMILYLABGBLACK: 'LabGrotesque-Black-subset, sans-serif',
  LINEHEIGHT: '1.4',
  MOZFONTSMOOTHING: 'grayscale',
  SIZE: {
    DEFAULT: '16px'
  },
  STYLE: {
    BOLD: 'bold',
    DEFAULT: 'normal',
    ITALIC: 'italic'
  },
  TEXTRENDERING: 'optimizeLegibility',
  WEBKITFONTSMOOTHING: 'antialiased',
  WEIGHT: {
    BOLD: 700,
    DEFAULT: 400,
    LIGHT: 400
  }
};

export const TYPOGRAPHY = {
  LIGHT: `
     font-family: ${FONT.FAMILYLABGREGULAR};
     font-weight: ${FONT.WEIGHT.DEFAULT};
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   `,
  BOLD: `
     font-family: ${FONT.FAMILYLABGBLACK};
     font-weight: ${FONT.WEIGHT.DEFAULT};
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   `
};

export const LAYOUT = {
  WIDTH: {
    DEFAULT: 'max-width: 1170px',
    WIDE: 'max-width: 1360px'
  },
  BREAKPOINT: {
    DESKTOP: 'min-width: 992px',
    ULTRA: 'min-width: 1440px'
  }
};

export const CUSTOM = {
  SCROLLBAR: `
      &::-webkit-scrollbar-track
      {
         -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
         background-color: ${COLOR.LIGHTGREY3};
      }
      
      &::-webkit-scrollbar
      {
         width: 10px;
         background-color: ${COLOR.LIGHTGREY3};
      }
      
      &::-webkit-scrollbar-thumb
      {
         -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
         background-color: ${COLOR.LIGHTGREY};
      }
    `
};
