import React from 'react';
import { COLOR } from '../theme/constants';
import styled from 'styled-components';
import { any } from 'prop-types';

interface NavigationMenuProps {
  children?: any;
}

const NavigationMenu = (props: NavigationMenuProps) => {
  return (
    <MenuWrapper>
      <MenuList>{props.children}</MenuList>
    </MenuWrapper>
  );
};

export const MenuWrapper = styled.div`
  border-bottom: 1px solid ${COLOR.LIGHTGREY};
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: ${COLOR.WHITE};
  z-index: 99;
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export default NavigationMenu;
