import { gql } from 'apollo-boost';

export const QUERY = gql`
  query {
    noteworthyAssets {
      latestAssets {
        assetType
        publicationDateUtc
        firstAvailableDateTimeUtc
        head
        url
      }
      upcomingAssetMonths {
        month
        upcomingAssets {
          assetType
          assetName
          liveDate
          landingPageLink
        }
      }
    }
  }
`;
