import React from 'react';
import styled from 'styled-components';
import { COLOR, TYPOGRAPHY } from '../theme/constants';

interface NavigationMenuItemProps {
  name: string;
  onClick: any;
  selected: boolean;
}

const NavigationMenuItem = (props: NavigationMenuItemProps) => {
  return (
    <MenuItem data-analytics={`NoteworthyContent-${props.name}-Menu`} onClick={props.onClick} selected={props.selected}>
      <MenuItemTitle selected={props.selected}>{props.name}</MenuItemTitle>
    </MenuItem>
  );
};

export const MenuItem = styled.div<{ selected: boolean }>`
  display: inline-flex;
  padding: 0 20px;
  cursor: pointer;
`;

const MenuItemTitle = styled.h2<{ selected: boolean }>`
  ${TYPOGRAPHY.BOLD};
  font-size: 14px;
  line-height: 1.25;
  color: ${({ selected }) => (selected ? COLOR.BLACK : COLOR.GREY)};
  border-bottom: ${({ selected }) => (selected ? `3px solid ${COLOR.RED}` : `none`)};
  margin: 10px 0px 0px 0px;
`;

export default NavigationMenuItem;
