import React from 'react';
import { COLOR } from '../theme/constants';
import styled from 'styled-components';

const Loading = () => {
  return (
    <Wrapper>
      <LoadingIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 475px;
`;

export const LoadingIcon = styled.div`
  border: 10px solid ${COLOR.LIGHTGREY};
  border-top: 10px solid ${COLOR.BLACK};
  border-bottom: 10px solid ${COLOR.BLACK};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
